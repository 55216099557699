footer {
  width: 100%;
  background: linear-gradient(#885438, #461D0D);
  padding-top: 80px;
  h3 {
    color: white;
    text-align: center;
    margin: 0;
  }
  .icon {
    width: 25px;
    display: flex;
    justify-content: center;
  }
}

.addressWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  img {
    margin-top: 8px;
    width: 15px;
    align-self: flex-start;
  }
  address {
    flex: 1 0 auto;
    color: $tan;
    font-size: 1.2rem;
    font-style: normal;
    transition: 0.3s all ease-in-out;
    &:hover {
      color: white;
      transition: 0.3s all ease-in-out;
    }
  }
}

.contactWrap {
  margin-top: 10px;
  font-size: 1.2rem;
  a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    &.phone {
      img {
        width: 12px;
      }
    }
    &.email {
      img {
        width: 20px;
      }
    }
  }
}

.footerWrap {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  box-sizing: border-box;
  padding: 40px;
  max-width: 1600px;
  margin: 0 auto;
  &.dealerWrap {
    flex-direction: column;
  }
  article {
    width: auto;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .copyWrap {
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    &.dealerList {
      gap: 20px;
    }
  }
  iframe {
    border: none;
    width: auto;
    min-height: 300px;
    flex: 0 0 50%;
    border-radius: 5px;
  }
  #Logo {
    align-self: center;
    text {
      fill: white;
    }
  }
}

@media (min-width: 768px) {
  .footerWrap {
    flex-direction: row;
    &.dealerWrap {
      flex-direction: row;
    }
    #Logo {
      align-self: flex-start;
    }
  }
}