.dotNav {
  display: flex;
  justify-content: center;
  padding: 0 0 20px;
  .dot {
    width: 30px;
    height: 30px;
    margin: 0 20px;
    background: rgba(255,255,255,0.2);
    border-radius: 99999px;
    transition: all 0.3s ease-in-out;
    &.active {
      background: rgba(255,255,255,1);
      transition: all 0.3s ease-in-out;
    }
    &:first-child {
      margin: 0 20px 0 0;
    }
  }
}
.carousel {
  position: relative;
  overflow-x: hidden;
  .carouselWrap {
    display: flex;
    align-items: center;
    transition: all 1s ease-in-out;
    position: relative;
    z-index: 2;
    &.resizing {
      transition: none;
    }
    .slide {
      display: block;
      flex: 0 0 100%;
      .slideImage {
        img {
          width: 80%;
          height: auto;
          margin: 0 auto;
          display: block;
        }
      }
      &:nth-child(3) {
        .slideImage {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .slideCopy {
        text-align: center;
        font-size: 1.4rem;
        h2 {
          margin: 20px 0 0;
          letter-spacing: 2px;
          &:after {
            margin-left: 10px;
            content: '';
            display: inline-block;
            height: 0px;
            width: 0px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 15px solid $tan;
            transform: translate(0, 0);
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            &:after {
              border-left: 15px solid white;
              transform: translate(10px, 0);
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
}

@media(min-width: 768px) {
  .dotNav {
    .dot {
      width: 20px;
      height: 20px;
      margin: 0 10px;
      &:first-child {
        margin: 0 10px 0 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .carousel {
    overflow-y: hidden;
    min-height: 600px;
    .carouselWrap {
      height: 100%;
      .slide {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        .slideImage {
          flex: 0 0 50%;
          img {
            width: 100%;
            height: auto;
            box-sizing: border-box;
          }
        }
        &:nth-child(1) {
          .slideImage {
            display: flex;
            align-items: center;
            img {
              width: 90%;
            }
          }
        }
        &:nth-child(2) {
          .slideImage {
            align-self: flex-start;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        &:nth-child(3) {
          .slideImage {
            display: flex;
            align-items: center;
          }
        }
        .slideCopy {
          flex: 0 0 50%;
          align-self: flex-end;
          position: relative;
          bottom: 15%;
          opacity: 1;
          transition: all 0.5s ease-in-out;
          h2 {
            text-align: left;
          }
          &.fade {
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
    .dotNav {
      position: absolute;
      z-index: 3;
      bottom: 25%;
      left: 50%;
      padding: 0;
    }
  }
}

@media (min-width: 991px) {
  .carousel {
    .carouselWrap {
      .slide {
        .slideImage {
          img {
            padding: 0 40px;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .carousel {
    .carouselWrap {
      .slide {
        .slideImage {
          flex: 0 0 50%;
          img {
            padding: 0 100px;
          }
        }
        &:nth-child(3) {
          .slideImage {
            img {
              padding: 0 40px;
            }
          }
        }
      }
    }
  }
}