@import url("https://use.typekit.net/yby0lpp.css");
html,
body {
  margin: 0;
  padding: 0;
  background: #223047;
  font-family: "stratum-1-web", sans-serif;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #BEA296;
}
.loader .scope {
  position: relative;
}
.loader .scope:after {
  content: "";
  display: block;
  border: 5px solid #223047;
  border-top: 5px solid #885438;
  border-radius: 9999px;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}
.loader .scope .sights {
  position: absolute;
  width: 100%;
  height: 100%;
}
.loader .scope .sights span {
  width: 5px;
  height: 30%;
  background: #223047;
  display: block;
  position: absolute;
}
.loader .scope .sights span:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.loader .scope .sights span:nth-child(2) {
  top: 50%;
  right: 0;
  transform: translate(0, 0) rotate(90deg);
  transform-origin: 100% 0;
}
.loader .scope .sights span:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.loader .scope .sights span:nth-child(4) {
  top: 50%;
  left: 0;
  transform: translate(0, 0) rotate(-90deg);
  transform-origin: 0 0;
}
.loader .scope .dot {
  display: block;
  width: 10px;
  height: 10px;
  background: #885438;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 999px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
a {
  color: white;
  text-decoration: none;
}

h2 {
  font-family: "clarendon-wide", serif;
  font-weight: 700;
  color: white;
}

h3 {
  font-size: 1.8rem;
  letter-spacing: 1px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  font-family: "new-frank", sans-serif;
}

h4 {
  font-family: "clarendon-wide", serif;
  letter-spacing: 1px;
  font-size: 1.4rem;
  margin: 0 0 10px;
}

h5.details {
  font-family: "stratum-1-web", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in-out;
}
h5.details:after {
  margin-left: 10px;
  content: "";
  display: inline-block;
  height: 0px;
  width: 0px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid rgba(255, 255, 255, 0.5);
  transform: translate(0, 0);
  transition: all 0.3s ease-in-out;
}

button {
  border: none;
  font-family: "stratum-1-web", sans-serif;
}
button.white {
  background: white;
  border: none;
  color: #223047;
  font-size: 1.6rem;
}

input {
  background: none;
  border: none;
  border-bottom: 2px solid white;
  border-radius: 0;
  color: white;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.4rem;
  box-sizing: border-box;
  padding: 5px 0;
  font-family: "stratum-1-web", sans-serif;
}
input:focus, input:active {
  outline: none;
  box-shadow: 0 0 0;
}
input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.btn {
  color: white;
  background: #7C8969;
  display: block;
  width: 300px;
  margin: 0 auto;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "stratum-1-web", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
}
.btn.blue {
  background: #223047;
}
.btn.full {
  width: 100%;
}
.btn.outline {
  background: none;
  border: 1px solid white;
  width: 100%;
}
.btn.outline.active {
  background: #4E6283;
}
.btn:hover {
  cursor: pointer;
}

.alert {
  display: block;
  max-width: 100%;
  padding: 10px;
  text-align: center;
  color: #270303;
}
.alert.error {
  background: #f46868;
}
.alert.info {
  color: white;
}

.contentWrap {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.dealerInquiry {
  background: linear-gradient(#885438, #461D0D);
  color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
}
.dealerInquiry h3 {
  margin: 0;
}
.dealerInquiry p {
  font-size: 1.4rem;
  max-width: 800px;
}
.dealerInquiry .btn {
  color: #223047;
  background: white;
  margin-top: 20px;
}

.hero {
  background: linear-gradient(#885438, #461D0D);
  position: relative;
}
.hero .heroTitle {
  height: 100%;
  width: 100%;
  color: white;
  font-size: 1.6rem;
  font-family: "new-frank", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  line-height: 50px;
  color: #BEA296;
  position: relative;
  z-index: 2;
}
.hero .heroTitle h1 {
  margin: 0;
  padding: 20px;
}
.hero .heroTitle * {
  flex: 0 0 50%;
}
.hero .heroTitle .btn {
  margin: 0 auto 20px;
}
.hero .heroImage {
  position: relative;
  z-index: 2;
}
.hero .heroImage img {
  width: 100%;
}
.hero:after {
  content: "";
  display: block;
  background: #223047;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30vh;
}

.company {
  padding-bottom: 0;
}
.company .companyCopy {
  color: white;
}
.company .companyCopy p {
  line-height: 34px;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  font-family: "stratum-1-web", sans-serif;
  color: rgba(255, 255, 255, 0.5);
}
.company .companyImage {
  position: relative;
  transform: translate(0, 25px);
}
.company .companyImage:after {
  content: "";
  display: block;
  width: 65%;
  height: 50px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 40px;
  background: rgba(0, 0, 0, 0.8);
  transform: rotate(10deg);
  filter: blur(30px);
}
.company .companyImage img {
  max-width: 100%;
  position: relative;
  z-index: 2;
}

.testimonials {
  min-height: 300px;
  background: #7C8969;
}
.testimonials .contentWrap {
  max-width: 1000px;
}
.testimonials h3 {
  color: #223047;
  text-align: center;
  margin: 40px 0 0;
}
.testimonials .testimonialWrap {
  margin: 60px 20px;
}
.testimonials .testimonialWrap .testimonialImage {
  transform: translate(0, 10px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.testimonials .testimonialWrap .testimonialImage div {
  width: 200px;
  height: 200px;
  border-radius: 99999px;
  display: block;
  background-size: cover;
  margin: 0 auto;
}
.testimonials .testimonialWrap .testimonialCopy {
  color: white;
  transform: translate(0, 10px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.testimonials .testimonialWrap .testimonialCopy blockquote {
  font-size: 1.6rem;
  margin: 20px 0;
  position: relative;
}
.testimonials .testimonialWrap .testimonialCopy blockquote:before {
  content: '"';
  font-size: 6rem;
  position: absolute;
  top: -30px;
  left: -15px;
  font-family: "clarendon-wide", serif;
  opacity: 0.2;
}
.testimonials .testimonialWrap .testimonialCopy cite {
  font-weight: bold;
  font-size: 2rem;
  text-align: left;
  display: block;
}
.testimonials .testimonialWrap .testimonialCopy cite:before {
  content: "- ";
}
.testimonials .testimonialWrap.show .testimonialCopy,
.testimonials .testimonialWrap.show .testimonialImage {
  transform: translate(0, 0);
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.testimonials .change {
  width: 50px;
  height: 50px;
  background: #223047;
  margin: 0 auto 40px;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonials .change svg {
  width: 60%;
  height: auto;
}
.testimonials .change svg g, .testimonials .change svg path, .testimonials .change svg polygon {
  fill: white;
}
.testimonials .change:hover {
  cursor: pointer;
}
.testimonials .change.rotate svg {
  animation: rotateBtn 1s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes rotateBtn {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
.productListing {
  margin-bottom: 100px;
}
.productListing .contentWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 80px 0;
  margin: 80px auto;
}
.productListing .contentWrap a {
  text-align: center;
  display: block;
  flex: 0 1 100%;
}
.productListing .contentWrap a img {
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}
.productListing .contentWrap a h4 {
  margin-bottom: 0;
}
.productListing .contentWrap a .price {
  font-size: 1.4rem;
  margin-bottom: 20px;
}
.productListing .contentWrap a:hover h5 {
  color: rgb(255, 255, 255);
  transition: all 0.3s ease-in-out;
}
.productListing .contentWrap a:hover h5:after {
  border-left: 10px solid white;
  transform: translate(5px, 0);
  transition: all 0.3s ease-in-out;
}
.productListing .contentWrap a:nth-child(3) img {
  width: 90%;
}

.inquiryModal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: #223047;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  transform: translate(0, 100vh);
  transition: all 0.5s ease-in-out;
}
.inquiryModal.show {
  transform: translate(0, 0);
  transition: all 0.5s ease-in-out;
}
.inquiryModal .inquiryCart {
  min-height: 100vh;
  background: #7C8969;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 20px 0;
  position: relative;
  gap: 10px;
}
.inquiryModal .inquiryCart .alert {
  margin: 0 auto;
  font-size: 2rem;
}
.inquiryModal .inquiryCart .inquiryProductWrap {
  display: flex;
  align-items: center;
}
.inquiryModal .inquiryCart .inquiryProductCopy {
  display: block;
  text-align: left;
  flex: 0 0 50%;
}
.inquiryModal .inquiryCart img {
  max-width: 100px;
  height: 100%;
  display: block;
  margin: 0 auto;
}
.inquiryModal .inquiryCart .formGroup {
  position: relative;
  width: 100px;
}
.inquiryModal .inquiryCart .formGroup input {
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  font-size: 2rem;
  padding: 5px 10px;
  margin: 0;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.inquiryModal .inquiryCart .formGroup input::-webkit-inner-spin-button, .inquiryModal .inquiryCart .formGroup input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.inquiryModal .inquiryCart .formGroup .arrow {
  display: block;
  position: absolute;
  right: -30px;
}
.inquiryModal .inquiryCart .formGroup .arrow.up {
  top: 10px;
  height: 0px;
  width: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
  transform: translate(0, 0);
  transition: all 0.3s ease-in-out;
}
.inquiryModal .inquiryCart .formGroup .arrow.down {
  bottom: 10px;
  height: 0px;
  width: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid white;
  transform: translate(0, 0);
  transition: all 0.3s ease-in-out;
}
.inquiryModal .inquiryCart .formGroup:hover .arrow {
  display: block;
  cursor: pointer;
}
.inquiryModal .inquiryCart h4,
.inquiryModal .inquiryCart .price {
  color: white;
}
.inquiryModal .inquiryCart h4 {
  font-family: "clarendon-wide", serif;
  margin: 10px 0 0;
  font-size: 1rem;
}
.inquiryModal .inquiryCart .price {
  font-size: 1.4rem;
}
.inquiryModal .inquiryCart .price .dealer {
  margin-left: 10px;
  display: none;
}
.inquiryModal .inquiryCart .price.dealer .retail {
  position: relative;
  opacity: 0.5;
}
.inquiryModal .inquiryCart .price.dealer .retail:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.inquiryModal .inquiryCart .price.dealer .dealer {
  display: initial;
}
.inquiryModal .phone-number-input input {
  border-radius: 0;
}
.inquiryModal .phone-number-input button {
  background: none;
  color: white;
  border-bottom: 2px solid white;
  border-radius: 0;
  font-size: 1.2rem;
}
.inquiryModal .inquiryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.inquiryModal .inquiryForm .formHeader {
  color: white;
  text-align: center;
  font-family: "clarendon-wide", serif;
}
.inquiryModal .inquiryForm .formHeader .price {
  font-size: 2rem;
  margin: 0;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-10px, 0);
}
.inquiryModal .inquiryForm .formHeader .price .ticker {
  font-size: 1rem;
  color: #7C8969;
  transform: translate(-5px, 0);
}
.inquiryModal .inquiryForm .formHeader h5 {
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.inquiryModal .inquiryForm .btnGroup {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border: 3px solid white;
}
.inquiryModal .inquiryForm .btnGroup button {
  flex: 0 0 33.33333%;
  background: none;
  color: white;
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  letter-spacing: 1px;
  border: none;
  margin: 0;
  outline: none;
}
.inquiryModal .inquiryForm .btnGroup button svg {
  height: 50px;
  width: auto;
  display: block;
  margin: 0 auto 10px;
}
.inquiryModal .inquiryForm .btnGroup button:hover {
  cursor: pointer;
  background: #334769;
}
.inquiryModal .inquiryForm .btnGroup button.selected {
  background: white;
  color: #223047;
}
.inquiryModal .inquiryForm .btnGroup button.selected svg * {
  fill: #223047;
}
.inquiryModal .inquiryForm .btnGroup button.selected:hover {
  background: white;
}
.inquiryModal .inquiryForm .formWrap {
  width: 100%;
}
.inquiryModal .inquiryForm .formGroup {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}
.inquiryModal .inquiryForm .formGroup.half {
  justify-content: space-between;
}
.inquiryModal .inquiryForm .formGroup input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.inquiryModal .inquiryForm .formGroup.formBtnGroup {
  display: block;
}
.inquiryModal .inquiryForm .formGroup.formBtnGroup label {
  color: white;
  margin-bottom: 10px;
  display: block;
}
.inquiryModal .inquiryForm .formSubmit {
  width: 100%;
}
.inquiryModal .inquiryForm .inquirySuccessMessage {
  color: white;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.closeModal {
  width: 30px;
  height: 30px;
  border: 3px solid white;
  border-radius: 999px;
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 20px;
  background: white;
}
.closeModal:before {
  content: "";
  width: 50%;
  height: 3px;
  background: #223047;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all 0.3s ease-in-out;
}
.closeModal:after {
  content: "";
  width: 50%;
  height: 3px;
  background: #223047;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: all 0.3s ease-in-out;
}
.closeModal:hover {
  cursor: pointer;
}
.closeModal:hover:before, .closeModal:hover:after {
  width: 60%;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .closeModal {
    width: 50px;
    height: 50px;
  }
  .hero {
    display: flex;
  }
  .hero .heroTitle {
    display: flex;
    align-items: flex-start;
    position: absolute;
    font-size: 2rem;
    line-height: 80px;
    margin-top: 50px;
    transform: translate(0, -60px);
  }
  .hero .heroTitle h1 {
    padding: 100px 0 0;
    font-size: 4rem;
    line-height: 60px;
  }
  .hero .heroTitle .btn {
    margin: 40px 0;
  }
  .hero .heroImage {
    flex: 0 0 50%;
  }
  .hero .heroImage img {
    max-height: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    margin: 0 auto;
    display: block;
  }
  .hero .heroImage.pad {
    padding-top: 100px;
  }
  .company {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    z-index: 4;
  }
  .company .companyCopy {
    flex: 0 0 50%;
    padding-right: 50px;
    align-self: center;
  }
  .company .companyCopy h3 {
    font-size: 2rem;
  }
  .company .companyCopy p {
    font-size: 1.4rem;
    line-height: 30px;
  }
  .testimonials .testimonialWrap .testimonialImage {
    transform: translate(-10px, 0);
  }
  .testimonials .testimonialWrap .testimonialCopy {
    transform: translate(10px, 0);
  }
  .productListing .contentWrap {
    display: flex;
    align-items: flex-end;
  }
  .productListing .contentWrap a {
    flex: 0 1 50%;
  }
  .inquiryModal .inquiryCart .alert.info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 1.2rem;
    background: #223047;
    color: white;
  }
  .inquiryModal .inquiryCart .inquiryProductCopy {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 50%;
  }
  .inquiryModal .inquiryCart h4 {
    margin: 0;
  }
  .inquiryModal .inquiryCart .formGroup {
    width: auto;
  }
  .inquiryModal .inquiryCart .formGroup input {
    width: 100px;
    padding-right: 30px;
    font-size: 3rem;
  }
  .inquiryModal .inquiryCart .formGroup .arrow {
    display: none;
    right: 10px;
  }
  .inquiryModal .inquiryCart .formGroup .arrow.up {
    top: 20px;
  }
  .inquiryModal .inquiryCart .formGroup .arrow.down {
    bottom: 20px;
  }
  .inquiryModal .inquiryCart .copyGroup {
    margin-left: 20px;
  }
}
@media (min-width: 991px) {
  .contentWrap {
    width: 900px;
    margin: 0 auto;
  }
  .hero .heroTitle h1 {
    font-size: 5rem;
    line-height: 80px;
  }
  .testimonials .testimonialWrap {
    display: flex;
    justify-content: center;
  }
  .testimonials .testimonialWrap .testimonialImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
  }
  .testimonials .testimonialWrap .testimonialCopy cite {
    text-align: left;
  }
  .company .companyImage {
    transform: translate(0, 24px);
  }
  .inquiryModal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inquiryModal .inquiryCart,
  .inquiryModal .inquiryForm {
    flex: 0 0 50%;
  }
  .inquiryModal .inquiryForm {
    padding: 0 60px;
  }
  .inquiryModal .inquiryForm .formSubmit,
  .inquiryModal .inquiryForm .formWrap {
    width: 80%;
  }
  .inquiryModal .inquiryCart h4 {
    font-size: 1.2rem;
  }
  .inquiryModal .inquiryCart .inquiryProductCopy {
    flex: 0 0 60%;
  }
}
@media (min-width: 1200px) {
  .contentWrap {
    width: 1200px;
    margin: 0 auto;
  }
  .productListing .contentWrap {
    width: 100%;
    flex-wrap: nowrap;
  }
  .company .companyImage {
    transform: translate(0, 32px);
  }
}
#productPage h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 0;
}
#productPage .price {
  font-size: 2rem;
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
#productPage p {
  opacity: 0.5;
}
#productPage .content {
  position: relative;
  z-index: 3;
  height: 100%;
}
#productPage .content .about {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
  color: white;
  height: 100%;
  overflow: hidden;
}
#productPage .content .about .blueprint {
  position: relative;
  width: 100%;
  padding-bottom: 60px;
}
#productPage .content .about .blueprint img {
  max-width: 80%;
  margin: 0 auto;
  display: block;
  opacity: 0.5;
}
#productPage .content .about .desc {
  max-width: 500px;
  box-sizing: border-box;
  padding: 20px;
}
#productPage .content .about .desc p {
  font-size: 2rem;
  margin-top: 0;
  opacity: 1;
}
#productPage .content .about .desc ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
#productPage .content .about .desc ul:after {
  content: "";
  position: absolute;
  left: 0;
  top: 4%;
  bottom: 4%;
  width: 1px;
  background: white;
}
#productPage .content .about .desc ul li {
  position: relative;
  font-size: 1.4rem;
  padding-left: 40px;
  padding: 5px 0 5px 40px;
}
#productPage .content .about .desc ul li:after {
  content: "";
  width: 30px;
  height: 1px;
  background: white;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
#productPage .content .about .desc .btn {
  margin: 40px 0;
  width: 100%;
}
#productPage .imageGallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: #BEA296;
  box-sizing: border-box;
  padding: 15px;
}
#productPage .imageGallery .image {
  flex: 0 0 25%;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: -5px;
}
#productPage .imageGallery .image img {
  width: 100%;
  height: auto;
  outline: solid 2px black;
  outline-offset: -2px;
  transition: all 0.3s ease-in-out;
}
#productPage .imageGallery .image img:hover {
  cursor: pointer;
  outline-offset: -10px;
  outline: solid 10px black;
  transition: all 0.3s ease-in-out;
}
#productPage .imageModal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  display: none;
  box-sizing: border-box;
  padding: 40px;
}
#productPage .imageModal.show {
  display: flex;
  flex-direction: column;
}
#productPage .imageModal .imageWrap {
  max-width: 1200px;
  max-height: 100%;
  width: 100%;
}
#productPage .imageModal .imageWrap .featureImage {
  max-height: 50vh;
  overflow: hidden;
}
#productPage .imageModal .imageWrap .featureImage img {
  width: 100%;
  height: 100%;
}
#productPage .imageModal .imageWrap .copy {
  color: white;
}
#productPage .imageModal .imageWrap .copy h5 {
  margin: 20px 0 0;
  font-size: 2rem;
}
#productPage .imageModal .imageWrap .copy p {
  font-size: 1.2rem;
}
#productPage .imageModal .imageWrap .copy .thumbs {
  display: none;
}
#productPage .imageModal .imageWrap .copy .thumbs img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
#productPage .block {
  display: flex;
  flex-direction: column;
  background: #4C4F58;
  color: white;
  font-family: "stratum-1-web", sans-serif;
}
#productPage .block h3 {
  font-family: "stratum-1-web", sans-serif;
  font-weight: lighter;
  margin: 0;
}
#productPage .block p {
  font-size: 1.4rem;
}
#productPage .block article {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#productPage .block article img {
  max-width: 80%;
}
#productPage .block .camo {
  aspect-ratio: 1;
  background: url("../img/camo.jpg") no-repeat scroll center/cover;
}
#productPage .block.viewPort {
  flex-direction: column-reverse;
}
#productPage .block.viewPort .camoWrap {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;
  background: #222;
}
#productPage .block.viewPort .camoWrap:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
}
#productPage .block.viewPort .camoWrap .cornImage {
  position: absolute;
  top: -70%;
  left: 0;
  right: 0;
  bottom: 0;
}
#productPage .block.viewPort .camoWrap .camoImage {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  transform: translate(-50%, -50%);
}
#productPage .block.viewPort .camoWrap .viewPortImage {
  position: relative;
  z-index: 3;
}
#productPage .block.solarPanel .camo {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#productPage .block.solarPanel .camo .glare {
  position: absolute;
  display: block;
  width: 300px;
  height: 300px;
  top: 200%;
  left: 50%;
  background: white;
  border-radius: 9999999px;
  filter: blur(100px);
  padding: 0;
}
#productPage .block.cascade {
  padding: 0;
}
#productPage .block.cascade .image {
  margin: 0;
  padding: 0;
}
#productPage .block.cascade .image img {
  max-width: 100%;
  margin-bottom: -5px;
}
#productPage .block.cascade .copy {
  padding: 20px;
}
#productPage .block.cascade article {
  padding: 0;
}
#productPage .motor-blueprint {
  display: none;
}
#productPage .spotlight {
  text-align: center;
  color: white;
  margin-top: 0;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 60px;
  position: relative;
  z-index: 2;
}
#productPage .spotlight img {
  max-width: 100%;
  max-height: 800px;
}
#productPage .related {
  display: flex;
  flex-direction: column;
  background: #7C8969;
}
#productPage .related .item {
  aspect-ratio: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px;
}
#productPage .related .item a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#productPage .related .item img {
  max-width: 60%;
}
#productPage .related .item h4 {
  margin-top: 40px;
}
#productPage .related .item:nth-child(1), #productPage .related .item:nth-child(3) {
  background: #626c53;
}
#productPage .related .item:nth-child(2) {
  background: #555e48;
}
#productPage .riceBrand {
  background: #223047;
}
#productPage .riceBrand article {
  aspect-ratio: 1;
}
#productPage .riceBrand article img {
  max-width: 80%;
}
#productPage .feedOptions {
  background: #223047;
}
#productPage .feedOptions .options {
  box-sizing: border-box;
  padding: 0 20px;
}
#productPage .feedOptions .options h3 {
  margin-top: 20px;
}
#productPage .feedOptions .options .btn {
  margin: 10px 0;
  width: 100%;
}
#productPage .feedOptions .graphic {
  position: relative;
}
#productPage .feedOptions .graphic svg {
  width: 100%;
}
#productPage .feedOptions .graphic svg #outline {
  fill: white;
}
#productPage .feedOptions .graphic svg #bg {
  fill: #4E6283;
}
#productPage .feedOptions .graphic svg #timer,
#productPage .feedOptions .graphic svg #left,
#productPage .feedOptions .graphic svg #right,
#productPage .feedOptions .graphic svg #gravity {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic svg polyline {
  fill: none;
  stroke-dasharray: 10;
  animation: dashPolyLine 5s linear infinite;
}
#productPage .feedOptions .graphic svg line {
  stroke-dasharray: 10;
  animation: dashLine 5s linear infinite;
}
#productPage .feedOptions .graphic.timer svg #timer {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic.left .imageWrap:after {
  transform: rotate(-45deg);
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic.left svg #right {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic.right .imageWrap:before {
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic.right .imageWrap:after {
  transform: rotate(0);
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic.right svg #left {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic.gravity .imageWrap:before {
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic.gravity .imageWrap:after {
  transform: rotate(-45deg);
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions .graphic.gravity svg #gravity {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.specialOps .imageWrap {
  position: relative;
  padding: 0;
}
#productPage .feedOptions.specialOps .imageWrap:before {
  content: "";
  display: block;
  background: white;
  width: 1px;
  height: 138px;
  position: absolute;
  z-index: 2;
  bottom: 105px;
  left: 53px;
  transform-origin: bottom center;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.specialOps .imageWrap:after {
  content: "";
  display: block;
  background: white;
  width: 1px;
  height: 138px;
  position: absolute;
  z-index: 2;
  bottom: 105px;
  right: 53px;
  transform: translate(0, 0);
  transform-origin: bottom center;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.fiveInOne .imageWrap {
  padding: 0;
  width: 375px;
}
#productPage .feedOptions.fiveInOne svg line {
  stroke-dasharray: 10;
  animation: dashPolyLine 5s linear infinite;
}
#productPage .feedOptions.surfNTurf .graphic svg {
  min-height: 500px;
  height: 100%;
  width: 100%;
}
#productPage .feedOptions.surfNTurf .graphic svg #timer,
#productPage .feedOptions.surfNTurf .graphic svg #timer-full,
#productPage .feedOptions.surfNTurf .graphic svg #left,
#productPage .feedOptions.surfNTurf .graphic svg #left-full {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.surfNTurf .graphic.timer svg #timer-full {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.surfNTurf .graphic.directional svg #timer {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.surfNTurf .graphic.split .imageWrap:before {
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.surfNTurf .graphic.split.directional svg #timer {
  opacity: 0;
}
#productPage .feedOptions.surfNTurf .graphic.split.directional svg #left {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.surfNTurf .graphic.split.timer svg #timer-full {
  opacity: 0;
}
#productPage .feedOptions.surfNTurf .graphic.split.timer svg #left-full {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
#productPage .feedOptions.surfNTurf .imageWrap {
  position: relative;
  padding: 0;
}
#productPage .feedOptions.surfNTurf .imageWrap:before {
  content: "";
  display: block;
  background: white;
  width: 1.5px;
  height: 148px;
  position: absolute;
  z-index: 2;
  bottom: 155px;
  left: 57px;
  transform-origin: bottom center;
  transition: all 0.3s ease-in-out;
}
#productPage.TreeHugger .spotlight {
  margin-top: -20px;
}

@keyframes dashLine {
  to {
    stroke-dashoffset: 100;
  }
}
@keyframes dashPolyLine {
  to {
    stroke-dashoffset: -100;
  }
}
@media (min-width: 768px) {
  #productPage.TreeHugger .block.viewPort .camoWrap .cornImage {
    top: -10%;
  }
  #productPage h2 {
    font-size: 4rem;
  }
  #productPage .content .about {
    flex-direction: row;
  }
  #productPage .content .about .blueprint {
    flex: 0 0 40%;
  }
  #productPage .content .about .blueprint img {
    position: absolute;
    top: 0;
    right: 60px;
    max-width: 130%;
    width: 130%;
  }
  #productPage .content .about .desc {
    flex: 0 0 60%;
  }
  #productPage .imageGallery {
    padding: 60px;
  }
  #productPage .imageGallery .featureImage {
    flex: 0 0 25%;
    padding: 10px;
  }
  #productPage .imageGallery .image img {
    outline: solid 5px black;
    outline-offset: -5px;
  }
  #productPage .imageGallery .copy h5 {
    margin: 0;
  }
  #productPage .imageModal .imageWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding-right: 40px;
  }
  #productPage .imageModal .imageWrap .featureImage {
    height: 100%;
    max-height: inherit;
    overflow: hidden;
  }
  #productPage .imageModal .imageWrap .featureImage img {
    object-position: 50% 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  #productPage .imageModal .imageWrap .copy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 400px;
    padding: 0 0 0 40px;
    color: white;
  }
  #productPage .imageModal .imageWrap .copy .btn {
    margin: 40px 0 0;
  }
  #productPage .imageModal .imageWrap .copy .thumbs {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 40px;
  }
  #productPage .imageModal .imageWrap .copy .thumbs .image {
    flex: 0 0 10%;
  }
  #productPage .block {
    flex-direction: row;
  }
  #productPage .block.viewPort {
    flex-direction: row;
  }
  #productPage .block.viewPort .camoWrap .cornImage {
    top: -20%;
  }
  #productPage .block.solarPanel .camo .glare {
    top: 125%;
  }
  #productPage .block article {
    flex: 0 0 50%;
  }
  #productPage .block article div {
    padding: 0 60px;
  }
  #productPage .block .copy div {
    padding: 0 40px;
  }
  #productPage .block .copy p {
    font-size: 1rem;
  }
  #productPage .block .copy h3 {
    margin-top: 0;
    font-size: 1.6rem;
  }
  #productPage .block.cascade {
    background: #223047;
    padding: 20px;
  }
  #productPage .block.cascade article {
    padding: 30px;
  }
  #productPage .block.cascade .image {
    margin: 0;
    padding: 0;
  }
  #productPage .block.cascade .image img {
    max-width: 100%;
    margin-bottom: -5px;
  }
  #productPage .block.cascade .copy {
    padding: 30px;
    border: 1px solid white;
  }
  #productPage .motor-blueprint {
    display: block;
    position: relative;
    z-index: 99;
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    pointer-events: none;
  }
  #productPage .motor-blueprint svg {
    overflow: visible;
  }
  #productPage .motor-blueprint svg g, #productPage .motor-blueprint svg polygon, #productPage .motor-blueprint svg path {
    fill: #4E6283;
    transition: all 0.3s ease-in-out;
  }
  #productPage .motor-blueprint svg#motorGraphic {
    width: 20%;
    display: block;
    position: absolute;
    top: 29%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  #productPage .motor-blueprint #motor {
    transform: rotate(0deg);
  }
  #productPage .motor-blueprint.guard #guard polygon {
    fill: white;
  }
  #productPage .motor-blueprint.door #motor {
    fill: white;
    transform-origin: 0 0;
    transform-box: view-box;
    transform: rotate(80deg) translate(45px, -40px);
    transition: all 0.3s ease-in-out;
  }
  #productPage .motor-blueprint.door #motor path {
    fill: white;
  }
  #productPage .spotlight {
    margin-top: -100px;
  }
  #productPage .related {
    flex-direction: row;
  }
  #productPage .feedOptions {
    padding: 60px 0;
  }
  #productPage .feedOptions .options {
    padding: 20px;
  }
  #productPage .feedOptions .options h3 {
    margin-top: 0;
  }
  #productPage .feedOptions.fiveInOne .imageWrap {
    width: 100%;
  }
  #productPage .feedOptions.surfNTurf .imageWrap:before {
    content: "";
    width: 1.5px;
    height: 173px;
    bottom: 130px;
    left: 68px;
  }
  #productPage .feedOptions.specialOps .imageWrap {
    width: 400px;
    position: relative;
    padding: 0;
  }
  #productPage .feedOptions.specialOps .imageWrap:before {
    content: "";
    display: block;
    background: white;
    width: 2px;
    height: 180px;
    position: absolute;
    z-index: 2;
    top: auto;
    right: auto;
    bottom: 140px;
    left: 71px;
    transform-origin: bottom center;
    transition: all 0.3s ease-in-out;
  }
  #productPage .feedOptions.specialOps .imageWrap:after {
    content: "";
    display: block;
    background: white;
    width: 2px;
    height: 180px;
    position: absolute;
    z-index: 2;
    bottom: 140px;
    right: 71px;
    transform: translate(0, 0);
    transform-origin: bottom center;
    transition: all 0.3s ease-in-out;
  }
}
@media (min-width: 1024px) {
  #productPage .block .copy div {
    padding: 0 60px;
  }
  #productPage .block .copy p {
    font-size: 1.4rem;
  }
  #productPage .block .copy h3 {
    margin-top: 0;
    font-size: 2rem;
  }
  #productPage .block.cascade {
    padding: 20px;
  }
  #productPage .block.cascade article {
    padding: 60px;
  }
  #productPage .block.cascade .copy {
    padding: 60px;
  }
  #productPage .imageModal .imageWrap .copy .thumbs .image {
    flex: 0 0 20%;
  }
}
@media (min-width: 1200px) {
  #productPage .block .copy div {
    padding: 0 100px;
  }
}
.dotNav {
  display: flex;
  justify-content: center;
  padding: 0 0 20px;
}
.dotNav .dot {
  width: 30px;
  height: 30px;
  margin: 0 20px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 99999px;
  transition: all 0.3s ease-in-out;
}
.dotNav .dot.active {
  background: rgb(255, 255, 255);
  transition: all 0.3s ease-in-out;
}
.dotNav .dot:first-child {
  margin: 0 20px 0 0;
}

.carousel {
  position: relative;
  overflow-x: hidden;
}
.carousel .carouselWrap {
  display: flex;
  align-items: center;
  transition: all 1s ease-in-out;
  position: relative;
  z-index: 2;
}
.carousel .carouselWrap.resizing {
  transition: none;
}
.carousel .carouselWrap .slide {
  display: block;
  flex: 0 0 100%;
}
.carousel .carouselWrap .slide .slideImage img {
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}
.carousel .carouselWrap .slide:nth-child(3) .slideImage img {
  width: 100%;
  height: auto;
}
.carousel .carouselWrap .slide .slideCopy {
  text-align: center;
  font-size: 1.4rem;
}
.carousel .carouselWrap .slide .slideCopy h2 {
  margin: 20px 0 0;
  letter-spacing: 2px;
}
.carousel .carouselWrap .slide .slideCopy h2:after {
  margin-left: 10px;
  content: "";
  display: inline-block;
  height: 0px;
  width: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #BEA296;
  transform: translate(0, 0);
  transition: all 0.3s ease-in-out;
}
.carousel .carouselWrap .slide .slideCopy h2:hover:after {
  border-left: 15px solid white;
  transform: translate(10px, 0);
  transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .dotNav .dot {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
  .dotNav .dot:first-child {
    margin: 0 10px 0 0;
  }
  .dotNav .dot:hover {
    cursor: pointer;
  }
  .carousel {
    overflow-y: hidden;
    min-height: 600px;
  }
  .carousel .carouselWrap {
    height: 100%;
  }
  .carousel .carouselWrap .slide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .carousel .carouselWrap .slide .slideImage {
    flex: 0 0 50%;
  }
  .carousel .carouselWrap .slide .slideImage img {
    width: 100%;
    height: auto;
    box-sizing: border-box;
  }
  .carousel .carouselWrap .slide:nth-child(1) .slideImage {
    display: flex;
    align-items: center;
  }
  .carousel .carouselWrap .slide:nth-child(1) .slideImage img {
    width: 90%;
  }
  .carousel .carouselWrap .slide:nth-child(2) .slideImage {
    align-self: flex-start;
  }
  .carousel .carouselWrap .slide:nth-child(2) .slideImage img {
    width: 100%;
    height: auto;
  }
  .carousel .carouselWrap .slide:nth-child(3) .slideImage {
    display: flex;
    align-items: center;
  }
  .carousel .carouselWrap .slide .slideCopy {
    flex: 0 0 50%;
    align-self: flex-end;
    position: relative;
    bottom: 15%;
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
  .carousel .carouselWrap .slide .slideCopy h2 {
    text-align: left;
  }
  .carousel .carouselWrap .slide .slideCopy.fade {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  .carousel .dotNav {
    position: absolute;
    z-index: 3;
    bottom: 25%;
    left: 50%;
    padding: 0;
  }
}
@media (min-width: 991px) {
  .carousel .carouselWrap .slide .slideImage img {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .carousel .carouselWrap .slide .slideImage {
    flex: 0 0 50%;
  }
  .carousel .carouselWrap .slide .slideImage img {
    padding: 0 100px;
  }
  .carousel .carouselWrap .slide:nth-child(3) .slideImage img {
    padding: 0 40px;
  }
}
.map {
  flex: 0 1 100%;
  position: relative;
  align-self: center;
}
.map .headquarters {
  position: absolute;
  top: 36%;
  left: 66%;
  color: gold;
  font-size: 1rem;
}
.map .dealerPin {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #BEA296;
  border-radius: 999px;
}
.map .dealerPin.active, .map .dealerPin:hover {
  background: white;
}
.map svg {
  width: 100%;
  margin: 0 auto;
  display: block;
}
.map svg path {
  fill: #BEA296;
}

.dealerList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dealerList .dealerBlock {
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
}
.dealerList .dealerBlock:hover, .dealerList .dealerBlock.active {
  background: rgba(0, 0, 0, 0.1);
}
.dealerList h4 {
  color: white;
  margin: 0;
}
.dealerList .addressWrap {
  display: flex;
}
.dealerList .addressWrap svg {
  width: 20px;
  height: 20px;
}

@media (min-width: 991px) {
  .map .headquarters {
    font-size: 2rem;
  }
  .map .dealerPin {
    width: 20px;
    height: 20px;
  }
}
footer {
  width: 100%;
  background: linear-gradient(#885438, #461D0D);
  padding-top: 80px;
}
footer h3 {
  color: white;
  text-align: center;
  margin: 0;
}
footer .icon {
  width: 25px;
  display: flex;
  justify-content: center;
}

.addressWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.addressWrap img {
  margin-top: 8px;
  width: 15px;
  align-self: flex-start;
}
.addressWrap address {
  flex: 1 0 auto;
  color: #BEA296;
  font-size: 1.2rem;
  font-style: normal;
  transition: 0.3s all ease-in-out;
}
.addressWrap address:hover {
  color: white;
  transition: 0.3s all ease-in-out;
}

.contactWrap {
  margin-top: 10px;
  font-size: 1.2rem;
}
.contactWrap a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}
.contactWrap a.phone img {
  width: 12px;
}
.contactWrap a.email img {
  width: 20px;
}

.footerWrap {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  box-sizing: border-box;
  padding: 40px;
  max-width: 1600px;
  margin: 0 auto;
}
.footerWrap.dealerWrap {
  flex-direction: column;
}
.footerWrap article {
  width: auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footerWrap .copyWrap {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}
.footerWrap .copyWrap.dealerList {
  gap: 20px;
}
.footerWrap iframe {
  border: none;
  width: auto;
  min-height: 300px;
  flex: 0 0 50%;
  border-radius: 5px;
}
.footerWrap #Logo {
  align-self: center;
}
.footerWrap #Logo text {
  fill: white;
}

@media (min-width: 768px) {
  .footerWrap {
    flex-direction: row;
  }
  .footerWrap.dealerWrap {
    flex-direction: row;
  }
  .footerWrap #Logo {
    align-self: flex-start;
  }
}

