@import url("https://use.typekit.net/yby0lpp.css");

$blue: #223047;
$lightblue: #4E6283;
$green: #7C8969;
$tan: #BEA296;
$orange: #885438;
$grey: #4C4F58;
$error: #f46868;

html,
body {
  margin: 0;
  padding: 0;
  background: $blue;
  font-family: "stratum-1-web", sans-serif;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $tan;
  .scope {
    position: relative;
    &:after {
      content: '';
      display: block;
      border: 5px solid $blue;
      border-top: 5px solid $orange;
      border-radius: 9999px;
      width: 80px;
      height: 80px;
      animation: spin 2s linear infinite;
    }
    .sights {
      position: absolute;
      width: 100%;
      height: 100%;
      span {
        width: 5px;
        height: 30%;
        background: $blue;
        display: block;
        position: absolute;
        &:nth-child(1) {
          top: 0;
          left: 50%;
          transform: translate(-50%,0);
        }
        &:nth-child(2) {
          top: 50%;
          right: 0;
          transform: translate(0, 0) rotate(90deg);
          transform-origin: 100% 0;
        }
        &:nth-child(3) {
          bottom: 0;
          left: 50%;
          transform: translate(-50%,0);
        }
        &:nth-child(4) {
          top: 50%;
          left: 0;
          transform: translate(0, 0) rotate(-90deg);
          transform-origin: 0 0;
        }
      }
    }
    .dot {
      display: block;
      width: 10px;
      height: 10px;
      background: $orange;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      border-radius: 999px;
    }
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
a {
  color: white;
  text-decoration: none;
}
h2 {
  font-family: "clarendon-wide", serif;
  font-weight: 700;
  color: white;
}
h3 {
  font-size: 1.8rem;
  letter-spacing: 1px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  font-family: "new-frank", sans-serif;
}
h4 {
  font-family: "clarendon-wide", serif;
  letter-spacing: 1px;
  font-size: 1.4rem;
  margin: 0 0 10px;
}
h5.details {
  font-family: "stratum-1-web", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  margin: 0;
  color: rgba(255,255,255,0.5);
  transition: all 0.3s ease-in-out;
  &:after {
    margin-left: 10px;
    content: '';
    display: inline-block;
    height: 0px;
    width: 0px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid rgba(255,255,255,0.5);
    transform: translate(0, 0);
    transition: all 0.3s ease-in-out;
  }
}
button {
  border: none;
  font-family: "stratum-1-web", sans-serif;
  &.white {
    background: white;
    border: none;
    color: $blue;
    font-size: 1.6rem;
  }
}
input {
  background: none;
  border: none;
  border-bottom: 2px solid white;
  border-radius: 0;
  color: white;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.4rem;
  box-sizing: border-box;
  padding: 5px 0;
  font-family: "stratum-1-web", sans-serif;
  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0;
  }
  &::placeholder {
    color: rgba(255,255,255,0.5);
  }
}
.btn {
  color: white;
  background: $green;
  display: block;
  width: 300px;
  margin: 0 auto;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "stratum-1-web", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  &.blue {
    background: $blue;
  }
  &.full {
    width: 100%;
  }
  &.outline {
    background: none;
    border: 1px solid white;
    width: 100%;
    &.active {
      background: $lightblue;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
.alert {
  display: block;
  max-width: 100%;
  padding: 10px;
  text-align: center;
  color: darken($error, 60%);
  &.error {
    background: $error;
  }
  &.info {
    color: white;
  }
}
.contentWrap {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.dealerInquiry {
  background: linear-gradient(#885438, #461D0D);
  color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  h3 {
    margin: 0;
  }
  p {
    font-size: 1.4rem;
    max-width: 800px;
  }
  .btn {
    color: $blue;
    background: white;
    margin-top: 20px;
  }
}
.hero {
  background: linear-gradient(#885438, #461D0D);
  position: relative;
  .heroTitle {
    height: 100%;
    width: 100%;
    color: white;
    font-size: 1.6rem;
    font-family: "new-frank", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    line-height: 50px;
    color: $tan;
    position: relative;
    z-index: 2;
    h1 {
      margin: 0;
      padding: 20px;
    }
    * {
      flex: 0 0 50%;
    }
    .btn {
      margin: 0 auto 20px;
    }
  }
  .heroImage {
    position: relative;
    z-index: 2;
    img {
      width: 100%;
    }
  }
  &:after {
    content: '';
    display: block;
    background: $blue;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30vh;
  }
}
.company {
  padding-bottom: 0;
  .companyCopy {
    color: white;
    p {
      line-height: 34px;
      font-size: 1.4rem;
      letter-spacing: 0.5px;
      font-family: "stratum-1-web", sans-serif;
      color: rgba(255,255,255,0.5);
    }
  }
  .companyImage {
    position: relative;
    transform: translate(0, 25px);
    &:after {
      content: '';
      display: block;
      width: 65%;
      height: 50px;
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 40px;
      background: rgba(0,0,0,0.8);
      transform: rotate(10deg);
      filter: blur(30px);
    }
    img {
      max-width: 100%;
      position: relative;
      z-index: 2;
    }
  }
}
.testimonials {
  min-height: 300px;
  background: $green;
  .contentWrap {
    max-width: 1000px;
  }
  h3 {
    color: $blue;
    text-align: center;
    margin: 40px 0 0;
  }
  .testimonialWrap {
    margin: 60px 20px;
    .testimonialImage {
      transform: translate(0,10px);
      opacity: 0;
      transition: all 0.5s ease-in-out;
      div {
        width: 200px;
        height: 200px;
        border-radius: 99999px;
        display: block;
        background-size: cover;
        margin: 0 auto;
      }
    }
    .testimonialCopy {
      color: white;
      transform: translate(0,10px);
      opacity: 0;
      transition: all 0.5s ease-in-out;
      blockquote {
        font-size: 1.6rem;
        margin: 20px 0;
        position: relative;
        &:before {
          content: '"';
          font-size: 6rem;
          position: absolute;
          top: -30px;
          left: -15px;
          font-family: "clarendon-wide", serif;
          opacity: 0.2;
        }
      }
      cite {
        font-weight: bold;
        font-size: 2rem;
        text-align: left;
        display: block;
        &:before {
          content: '- ';
        }
      }
    }
    &.show {
      .testimonialCopy,
      .testimonialImage {
        transform: translate(0,0);
        opacity: 1;
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .change {
    width: 50px;
    height: 50px;
    background: $blue;
    margin: 0 auto 40px;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 60%;
      height: auto;
      g,path, polygon {
        fill: white;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &.rotate {
      svg {
        animation: rotateBtn 1s ease-in-out;
        animation-fill-mode: forwards;
      }
    }
  }
}
@keyframes rotateBtn {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
.productListing {
  margin-bottom: 100px;
  .contentWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 80px 0;
    margin: 80px auto;
    a {
      text-align: center;
      display: block;
      flex: 0 1 100%;
      img {
        width: 80%;
        height: auto;
        margin: 0 auto;
        display: block;
      }
      h4 {
        margin-bottom: 0;
      }
      .price {
        font-size: 1.4rem;
        margin-bottom: 20px;
      }
      &:hover {
        h5 {
          color: rgba(255,255,255,1);
          transition: all 0.3s ease-in-out;
          &:after {
            border-left: 10px solid white;
            transform: translate(5px, 0);
            transition: all 0.3s ease-in-out;
          }
        }
      }
      // &:nth-child(1),
      // &:nth-child(3) {
      //   img {
      //     width: 70%;
      //   }
      // }
      &:nth-child(3) {
        img {
          width: 90%;
        }
      }
    }
  }
}
.inquiryModal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: $blue;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  transform: translate(0, 100vh);
  transition: all 0.5s ease-in-out;
  &.show {
    transform: translate(0, 0);
    transition: all 0.5s ease-in-out;
  }
  .inquiryCart {
    min-height: 100vh;
    background: $green;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 20px 0;
    position: relative;
    gap: 10px;
    .alert {
      margin: 0 auto;
      font-size: 2rem;
    }
    .inquiryProductWrap {
      display: flex;
      align-items: center;
    }
    .inquiryProductCopy {
      display: block;
      text-align: left;
      flex: 0 0 50%;
    }
    img {
      max-width: 100px;
      height: 100%;
      display: block;
      margin: 0 auto;
    }
    .formGroup {
      position: relative;
      width: 100px;
      input {
        width: 100px;
        border: 3px solid white;
        border-radius: 10px;
        font-size: 2rem;
        padding: 5px 10px;
        margin: 0;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        &::-webkit-inner-spin-button, 
        &::-webkit-outer-spin-button { 
          -webkit-appearance: none;
        }
      }
      .arrow {
        display: block;
        position: absolute;
        right: -30px;
        &.up {
          top: 10px;
          height: 0px;
          width: 0px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 15px solid white;
          transform: translate(0, 0);
          transition: all 0.3s ease-in-out;
        }
        &.down {
          bottom: 10px;
          height: 0px;
          width: 0px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 15px solid white;
          transform: translate(0, 0);
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover {
        .arrow {
          display: block;
          cursor: pointer;
        }
      }
    }
    h4,
    .price {
      color: white;
    }
    h4 {
      font-family: "clarendon-wide", serif;
      margin: 10px 0 0;
      font-size: 1rem;
    }
    .price {
      font-size: 1.4rem;
      .dealer {
        margin-left: 10px;
        display: none;
      }
      &.dealer {
        .retail {
          position: relative;
          opacity: 0.5;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background: white;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .dealer {
          display: initial;
        }
      }
    }
  }
  .phone-number-input {
    input {
      border-radius: 0;
    }
    button {
      background: none;
      color: white;
      border-bottom: 2px solid white;
      border-radius: 0;
      font-size: 1.2rem;
    }
  }
  .inquiryForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .formHeader {
      color: white;
      text-align: center;
      font-family: "clarendon-wide", serif;
      .price {
        font-size: 2rem;
        margin: 0;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-10px,0);
        .ticker {
          font-size: 1rem;
          color: $green;
          transform: translate(-5px, 0)
        }
      }
      h5 {
        margin: 0;
        font-size: 1.2rem;
        letter-spacing: 1px;
      }
    }
    .btnGroup {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      border: 3px solid white;
      button {
        flex: 0 0 33.33333%;
        background: none;
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
        letter-spacing: 1px;
        border: none;
        margin: 0;
        outline: none;
        svg {
          height: 50px;
          width: auto;
          display: block;
          margin: 0 auto 10px;
        }
        &:hover {
          cursor: pointer;
          background: lighten($blue, 10%);
        }
        &.selected {
          background: white;
          color: $blue;
          svg {
            * {
              fill: $blue;
            }
          }
          &:hover {
            background: white;
          }
        }
      }
    }
    .formWrap {
      width: 100%;
    }
    .formGroup {
      display: flex;
      gap: 20px;
      margin: 10px 0;
      &.half {
        justify-content: space-between;
      }
      input {
        &::placeholder {
          color: rgba(255,255,255,0.5);
        }
      }
      &.formBtnGroup {
        display: block;
        label {
          color: white;
          margin-bottom: 10px;
          display: block;
        }
      }
    }
    .formSubmit {
      width: 100%;
    }
    .inquirySuccessMessage {
      color: white;
      font-size: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
}

.closeModal {
  width: 30px;
  height: 30px;
  border: 3px solid white;
  border-radius: 999px;
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 20px;
  background: white;
  &:before {
    content: '';
    width: 50%;
    height: 3px;
    background: $blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
  }
  &:after {
    content: '';
    width: 50%;
    height: 3px;
    background: $blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(-45deg);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    cursor: pointer;
    &:before,
    &:after {
      width: 60%;
      transition: all 0.3s ease-in-out;
    }
  }
}

@media(min-width: 768px) {
  .closeModal {
    width: 50px;
    height: 50px;
  }
  .hero {
    display: flex;
    .heroTitle {
      display: flex;
      align-items: flex-start;
      position: absolute;
      font-size: 2rem;
      line-height: 80px;
      margin-top: 50px;
      transform: translate(0,-60px);
      h1 {
        padding: 100px 0 0;
        font-size: 4rem;
        line-height: 60px;
      }
      .btn {
        margin: 40px 0;
      }
    }
    .heroImage {
      flex: 0 0 50%;
      img {
        max-height: 100%;
        padding: 0 60px;
        box-sizing: border-box;
        margin: 0 auto;
        display: block;
      }
      &.pad {
        padding-top: 100px;
      }
    }
  }
  .company {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    z-index: 4;
    .companyCopy {
      flex: 0 0 50%;
      padding-right: 50px;
      align-self: center;
      h3 {
        font-size: 2rem;
      }
      p {
        font-size: 1.4rem;
        line-height: 30px;
      }
    }
  }
  .testimonials {
    .testimonialWrap {
      .testimonialImage {
        transform: translate(-10px,0);
      }
      .testimonialCopy {
        transform: translate(10px,0);
      }
    }
  }
  .productListing {
    .contentWrap {
      display: flex;
      align-items: flex-end;
      a {
        flex: 0 1 50%;
      }
    }
  }
  .inquiryModal {
    .inquiryCart {
      .alert {
        &.info {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          font-size: 1.2rem;
          background: $blue;
          color: white;
        }
      }
      .inquiryProductCopy {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 0 0 50%;
      }
      h4 {
        margin: 0;
      }
      .formGroup {
        width: auto;
        input {
          width: 100px;
          padding-right: 30px;
          font-size: 3rem;
        }
        .arrow {
          display: none;
          right: 10px;
          &.up {
            top: 20px;
          }
          &.down {
            bottom: 20px;
          }
        }
      }
      .copyGroup {
        margin-left: 20px;
      }
    }
  }
}

@media(min-width: 991px) {
  .contentWrap {
    width: 900px;
    margin: 0 auto;
  }
  .hero {
    .heroTitle {
      h1 {
        font-size: 5rem;
        line-height: 80px;
      }
    }
  }
  .testimonials {
    .testimonialWrap {
      display: flex;
      justify-content: center;
      .testimonialImage {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
      }
      .testimonialCopy {
        cite {
          text-align: left;
        }
      }
    }
  }
  .company {
    .companyImage {
      transform: translate(0,24px);
    }
  }
  .inquiryModal {
    display: flex;
    justify-content: center;
    align-items: center;
    .inquiryCart,
    .inquiryForm {
      flex: 0 0 50%;
    }
    .inquiryForm {
      padding: 0 60px;
      .formSubmit,
      .formWrap {
        width: 80%;
      }
    }
    .inquiryCart {
      h4 {
        font-size: 1.2rem;
      }
      .inquiryProductCopy {
        flex: 0 0 60%;
      }
    }
  }
}

@media(min-width: 1200px) {
  .contentWrap {
    width: 1200px;
    margin: 0 auto;
  }
  .productListing {
    .contentWrap {
      width: 100%;
      flex-wrap: nowrap;
    }
  }
  .company {
    .companyImage {
      transform: translate(0,32px);
    }
  }
}

@import './_product.scss';
@import './_carousel.scss';
@import './_dealerList.scss';
@import './_footer.scss';