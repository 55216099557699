#productPage {
  h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 0;
  }
  .price {
    font-size: 2rem;
    text-align: center;
    color: white;
    margin-bottom: 40px;
  }
  p {
    opacity: 0.5;
  }
  .content {
    position: relative;
    z-index: 3;
    height: 100%;
    .about {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: flex-start;
      color: white;
      height: 100%;
      overflow: hidden;
      .blueprint {
        position: relative;
        width: 100%;
        padding-bottom: 60px;
        img {
          max-width: 80%;
          margin: 0 auto;
          display: block;
          opacity: 0.5;
        }
      }
      .desc {
        max-width: 500px;
        box-sizing: border-box;
        padding: 20px;
        p {
          font-size: 2rem;
          margin-top: 0;
          opacity: 1;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 4%;
            bottom: 4%;
            width: 1px;
            background: white;
          }
          li {
            position: relative;
            font-size: 1.4rem;
            padding-left: 40px;
            padding: 5px 0 5px 40px;
            &:after {
              content: '';
              width: 30px;
              height: 1px;
              background: white;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translate(0,-50%);
            }
          }
        }
        .btn {
          margin: 40px 0;
          width: 100%;
        }
      }
    }
  }
  .imageGallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: $tan;
    box-sizing: border-box;
    padding: 15px;
    .image {
      flex: 0 0 25%;
      box-sizing: border-box;
      padding: 5px;
      margin-bottom: -5px;
      img {
        width: 100%;
        height: auto;
        outline: solid 2px black;
        outline-offset: -2px;
        transition: all 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          outline-offset: -10px;
          outline: solid 10px black;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .imageModal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.9);
    display: none;
    box-sizing: border-box;
    padding: 40px;
    &.show {
      display: flex;
      flex-direction: column;
    }
    .imageWrap {
      max-width: 1200px;
      max-height: 100%;
      width: 100%;
      .featureImage {
        max-height: 50vh;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .copy {
        color: white;
        h5 {
          margin: 20px 0 0;
          font-size: 2rem;
        }
        p {
          font-size: 1.2rem;
        }
        .thumbs {
          display: none;
          img {
            max-width: 100%;
            margin: 0 auto;
            display: block;
          }
        }
      }
    }
  }
  .block {
    display: flex;
    flex-direction: column;
    background: $grey;
    color: white;
    font-family: "stratum-1-web", sans-serif;
    h3 {
      font-family: "stratum-1-web", sans-serif;
      font-weight: lighter;
      margin: 0;
    }
    p {
      font-size: 1.4rem;
    }
    article {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        max-width: 80%;
      }
    }
    .camo {
      aspect-ratio: 1;
      background: url('../img/camo.jpg') no-repeat scroll center / cover;
    }
    &.viewPort {
      flex-direction: column-reverse;
      .camoWrap {
        position: relative;
        overflow: hidden;
        aspect-ratio: 1;
        background: #222;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          background: rgba(0,0,0,0.7);
        }
        .cornImage {
          position: absolute;
          top: -70%;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .camoImage {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          right: 0;
          bottom: 0;
          width: 100%;
          max-width: 100%;
          transform: translate(-50%,-50%);
        }
        .viewPortImage {
          position: relative;
          z-index: 3;
        }
      }
    }
    &.solarPanel {
      .camo {
        position: relative;
        z-index: 1;
        overflow: hidden;
        .glare {
          position: absolute;
          display: block;
          width: 300px;
          height: 300px;
          top: 200%;
          left: 50%;
          background: white;
          border-radius: 9999999px;
          filter: blur(100px);
          padding: 0;
        }
      }
    }
    &.cascade {
      padding: 0;
      .image {
        margin: 0;
        padding: 0;
        img {
          max-width: 100%;
          margin-bottom: -5px;
        }
      }
      .copy {
        padding: 20px;
      }
      article {
        padding: 0;
      }
    }
  }
  .motor-blueprint {
    display: none;
  }
  .spotlight {
    text-align: center;
    color: white;
    margin-top: 0;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 60px;
    position: relative;
    z-index: 2;
    img {
      max-width: 100%;
      max-height: 800px;
    }
  }
  .related {
    display: flex;
    flex-direction: column;
    background: $green;
    .item {
      aspect-ratio: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      padding: 40px;
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      img {
        max-width: 60%;
      }
      h4 {
        margin-top: 40px;
      }
      &:nth-child(1),
      &:nth-child(3) {
        background: darken($green, 10%);
      }
      &:nth-child(2) {
        background: darken($green, 15%);
      }
    }
  }
  .riceBrand {
    background: $blue;
    article {
      aspect-ratio: 1;
      img {
        max-width: 80%;
      }
    }
  }
  .feedOptions {
    background: $blue;
    .options {
      box-sizing: border-box;
      padding: 0 20px;
      h3 {
        margin-top: 20px;
      }
      .btn {
        margin: 10px 0;
        width: 100%;
      }
    }
    .graphic {
      position: relative;
      svg {
        width: 100%;
        #outline {
          fill: white;
        }
        #bg {
          fill: #4E6283;
        }
        #timer, 
        #left,
        #right,
        #gravity {
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }
        polyline {
          fill: none;
          stroke-dasharray: 10;
          animation: dashPolyLine 5s linear infinite;
        }
        line {
          stroke-dasharray: 10;
          animation: dashLine 5s linear infinite;
        }
      }
      &.timer {
        svg {
          #timer {
            opacity: 1;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      &.left {
        .imageWrap {
          &:after {
            transform: rotate(-45deg);
            transition: all 0.3s ease-in-out;
          }
        }
        svg {
          #right {
            opacity: 1;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      &.right {
        .imageWrap {
          &:before {
            transform: rotate(45deg);
            transition: all 0.3s ease-in-out;
          }
          &:after {
            transform: rotate(0);
            transition: all 0.3s ease-in-out;
          }
        }
        svg {
          #left {
            opacity: 1;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      &.gravity {
        .imageWrap {
          &:before {
            transform: rotate(45deg);
            transition: all 0.3s ease-in-out;
          }
          &:after {
            transform: rotate(-45deg);
            transition: all 0.3s ease-in-out;
          }
        }
        svg {
          #gravity {
            opacity: 1;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
    &.specialOps {
      .imageWrap {
        position: relative;
        padding: 0;
        &:before {
          content: '';
          display: block;
          background: white;
          width: 1px;
          height: 138px;
          position: absolute;
          z-index: 2;
          bottom: 105px;
          left: 53px;
          transform-origin: bottom center;
          transition: all 0.3s ease-in-out;
        }
        &:after {
          content: '';
          display: block;
          background: white;
          width: 1px;
          height: 138px;
          position: absolute;
          z-index: 2;
          bottom: 105px;
          right: 53px;
          transform: translate(0,0);
          transform-origin: bottom center;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    &.fiveInOne {
      .imageWrap {
        padding: 0;
        width: 375px;
      }
      svg {
        line {
          stroke-dasharray: 10;
          animation: dashPolyLine 5s linear infinite;
        }
      }
    }
    &.surfNTurf {
      .graphic {
        svg {
          min-height: 500px;
          height: 100%;
          width: 100%;
          #timer,
          #timer-full,
          #left,
          #left-full {
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }
        }
        &.timer {
          svg {
            #timer-full {
              opacity: 1;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        &.directional {
          svg {
            #timer {
              opacity: 1;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        &.split {
          .imageWrap {
            &:before {
              transform: rotate(45deg);
              transition: all 0.3s ease-in-out;
            }
          }
          &.directional {
            svg {
              #timer {
                opacity: 0;
              }
              #left {
                opacity: 1;
                transition: all 0.3s ease-in-out;
              }
            }
          }
          &.timer {
            svg {
              #timer-full {
                opacity: 0;
              }
              #left-full {
                opacity: 1;
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }
      .imageWrap {
        position: relative;
        padding: 0;
        &:before {
          content: '';
          display: block;
          background: white;
          width: 1.5px;
          height: 148px;
          position: absolute;
          z-index: 2;
          bottom: 155px;
          left: 57px;
          transform-origin: bottom center;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  &.TreeHugger {
    .spotlight {
      margin-top: -20px;
    }
  }
}

@keyframes dashLine {
  to {
    stroke-dashoffset: 100;
  }
}

@keyframes dashPolyLine {
  to {
    stroke-dashoffset: -100;
  }
}

@media(min-width: 768px) {
  #productPage {
    &.TreeHugger {
      .block {
        &.viewPort {
          .camoWrap {
            .cornImage {
              top: -10%;
            }
          }
        }
      }
    }
    h2 {
      font-size: 4rem;
    }
    .content {
      .about {
        flex-direction: row;
        .blueprint {
          flex: 0 0 40%;
          img {
            position: absolute;
            top: 0;
            right: 60px;
            max-width: 130%;
            width: 130%;
          }
        }
        .desc {
          flex: 0 0 60%;
        }
      }
    }
    .imageGallery {
      padding: 60px;
      .featureImage {
        flex: 0 0 25%;
        padding: 10px;
      }
      .image {
        img {
          outline: solid 5px black;
          outline-offset: -5px;
        }
      }
      .copy {
        h5 {
          margin: 0;
        }
      }
    }
    .imageModal {
      .imageWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        padding-right: 40px;
        .featureImage {
          height: 100%;
          max-height: inherit;
          overflow: hidden;
          img {
            object-position: 50% 50%;
            object-fit: cover;
            width:100%;
            height:100%;
          }
        }
        .copy {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          max-width: 400px;
          padding: 0 0 0 40px;
          color: white;
          .btn {
            margin: 40px 0 0;
          }
          .thumbs {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-top: 40px;
            .image {
              flex: 0 0 10%;
            }
          }
        }
      }
    }
    .block {
      flex-direction: row;
      &.viewPort {
        flex-direction: row;
        .camoWrap {
          .cornImage {
            top: -20%;
          }
        }
      }
      &.solarPanel {
        .camo {
          .glare {
            top: 125%;
          }
        }
      }
      article {
        flex: 0 0 50%;
        div {
          padding: 0 60px;
        }
      }
      .copy {
        div {
          padding: 0 40px;
        }
        p {
          font-size: 1rem;
        }
        h3 {
          margin-top: 0;
          font-size: 1.6rem;
        }
      }
      &.cascade {
        background: $blue;
        padding: 20px;
        article {
          padding: 30px;
        }
        .image {
          margin: 0;
          padding: 0;
          img {
            max-width: 100%;
            margin-bottom: -5px;
          }
        }
        .copy {
          padding: 30px;
          border: 1px solid white;
        }
      }
    }
    .motor-blueprint {
      display: block;
      position: relative;
      z-index: 99;
      max-width: 1024px;
      margin: 0 auto;
      padding: 20px;
      box-sizing: border-box;
      pointer-events: none;
      svg {
        overflow: visible;
        g, polygon, path {
          fill: $lightblue;
          transition: all 0.3s ease-in-out;
        }
        &#motorGraphic {
          width: 20%;
          display: block;
          position: absolute;
          top: 29%;
          left: 50%;
          transform: translate(-50%,0);
        }
      }
      #motor {
        transform: rotate(0deg);
      }
      &.guard {
        #guard {
          polygon {
            fill: white;
          }
        }
      }
      &.door {
        #motor {
          fill: white;
          transform-origin: 0 0;
          transform-box: view-box;
          transform: rotate(80deg) translate(45px, -40px);
          transition: all 0.3s ease-in-out;
          path {
            fill: white;
          }
        }
      }
    }
    .spotlight {
      margin-top: -100px;
    }
    .related {
      flex-direction: row;
    }
    .feedOptions {
      padding: 60px 0;
      .options {
        padding: 20px;
        h3 {
          margin-top: 0;
        }
      }
      &.fiveInOne {
        .imageWrap {
          width: 100%;
        }
      }
      &.surfNTurf {
        .imageWrap {
          &:before {
            content: '';
            width: 1.5px;
            height: 173px;
            bottom: 130px;
            left: 68px;
          }
        }
      }
      &.specialOps {
        .imageWrap {
          width: 400px;
          position: relative;
          padding: 0;
          &:before {
            content: '';
            display: block;
            background: white;
            width: 2px;
            height: 180px;
            position: absolute;
            z-index: 2;
            top: auto;
            right: auto;
            bottom: 140px;
            left: 71px;
            transform-origin: bottom center;
            transition: all 0.3s ease-in-out;
          }
          &:after {
            content: '';
            display: block;
            background: white;
            width: 2px;
            height: 180px;
            position: absolute;
            z-index: 2;
            bottom: 140px;
            right: 71px;
            transform: translate(0,0);
            transform-origin: bottom center;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}

@media(min-width: 1024px) {
  #productPage {
    .block {
      .copy {
        div {
          padding: 0 60px;
        }
        p {
          font-size: 1.4rem;
        }
        h3 {
          margin-top: 0;
          font-size: 2rem;
        }
      }
      &.cascade {
        padding: 20px;
        article {
          padding: 60px;
        }
        .copy {
          padding: 60px;
        }
      }
    }
    .imageModal {
      .imageWrap {
        .copy {
          .thumbs {
            .image {
              flex: 0 0 20%;
            }
          }
        }
      }
    }
  }
}

@media(min-width: 1200px) {
  #productPage {
    .block {
      .copy {
        div {
          padding: 0 100px;
        }
      }
    }
  }
}
