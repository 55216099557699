.map {
  flex: 0 1 100%;
  position: relative;
  align-self: center;
  .headquarters {
    position: absolute;
    top: 36%;
    left: 66%;
    color: gold;
    font-size: 1rem;
  }
  .dealerPin {
    position: absolute;
    width: 10px;
    height: 10px;
    background: $tan;
    border-radius: 999px;
    &.active,
    &:hover {
      background: white;
    }
  }
  svg {
    width: 100%;
    margin: 0 auto;
    display: block;
    path {
      fill: $tan;
    }
  }
}

.dealerList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .dealerBlock {
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    &:hover,
    &.active {
      background: rgba(0,0,0,0.1);
    }
  }
  h4 {
    color: white;
    margin: 0;
  }
  .addressWrap {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media (min-width: 991px) {
  .map {
    .headquarters {
      font-size: 2rem;
    }
    .dealerPin {
      width: 20px;
      height: 20px;
    }
  }
}